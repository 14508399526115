<template>
    <div class="top-mobile-search">
        <div class="row">
            <div class="col-md-12">   
                <form class="mobile-search">
                    <div class="input-group">
                        <input type="text" placeholder="Search for..." class="form-control">
                        <div class="input-group-append">
                            <button type="button" class="btn btn-dark"><i class="fas fa-search"></i></button>
                        </div>
                    </div>
                </form>   
            </div>
        </div>
    </div>
    <!-- <div class="top-category section-padding mb-4"> -->
       <!-- <app-slider/> -->
    <!-- </div> -->
    <!-- <hr> -->
    <div class="video-block section-padding">
        <div class="row">
            <div class="col-md-12">
                <div class="main-title">
                    <!-- <div class="btn-group float-right right-action">
                        <a href="#" class="right-action-link text-gray" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Sort by <i class="fa fa-caret-down" aria-hidden="true"></i>
                            </a>
                         <div class="dropdown-menu dropdown-menu-right">
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top Rated</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp; Viewed</a>
                            <a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i> &nbsp; Close</a>
                        </div>
                    </div> -->
                    <h6>الاعلي مشاهدة</h6>
                </div>
            </div>
            <div class="col-6 col-sm-4 col-lg-3 col-xl-3" v-for="(publish, index) in categories" :key="index">
                <div class="video-card">
                    <div class="video-card-image">
                        <a class="play-icon" @click="getFeed(publish)"><i class="fas fa-play-circle"></i></a>
                        <a @click="getFeed(publish)"><img class="img-fluid" v-lazy="publish.img" alt></a>
                        <div class="time">{{ publish.file_duration }}</div>
                    </div>
                    <div class="video-card-body">
                        <div class="video-title">
                            <a @click="getFeed(publish)">{{ publish.title_ar }}</a>
                        </div>
                        <div class="video-page text-success">
                            {{ publish.name_ar }}  <a title data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                        </div>
                        <div class="video-view">
                             &nbsp;<i class="fas fa-calendar-alt"></i> {{ publish.upload_date.substring(0,10) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="mt-0">
    <div class="video-block section-padding" style="margin-top: 4vh;">
        <div class="row">
            <div class="col-md-12">
                <div class="main-title">
                    <h6>اضيفت موخراً</h6>
                </div>
            </div>
            <div class="col-6 col-sm-4 col-lg-3 col-xl-3" v-for="(publish, index) in All.slice(0,8)" :key="index">
                <div class="video-card">
                    <div class="video-card-image">
                        <a class="play-icon" @click="getFeed(publish)"><i class="fas fa-play-circle"></i></a>
                        <a @click="getFeed(publish)"><img class="img-fluid" v-lazy="publish.img" alt></a>
                        <div class="time">{{ publish.file_duration }}</div>
                    </div>
                    <div class="video-card-body">
                        <div class="video-title">
                            <a @click="getFeed(publish)">{{ publish.title_ar }}</a>
                        </div>
                        <div class="video-page text-success">
                            {{ publish.name_ar }}  <a title data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                        </div>
                        <div class="video-view">
                             &nbsp;<i class="fas fa-calendar-alt"></i> {{ publish.upload_date.substring(0,10) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from 'vue';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
// import AppSlider from './AppSlider.vue';
export default {
//   components: { AppSlider },
    name: 'AppHome',
    // mounted() {
    //     const s = document.createElement('script');
    //     s.type = 'text/javascript';
    //     s. src = '/static/vendor/owl-carousel/owl.carousel.js';
    //     document.body.appendChild(s); 
    // },
    async setup() {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie()
        const categories = ref([]);
        const All = ref([]);

        try {
            await HTTP.get(`GetMostViewed.php?LIMIT=8`).then((res) => {
                categories.value = res.data.MostViewed;
            });
        } catch (err) {
            console.log(err);
        }

        try {
            await HTTP.get(`GetCarousol.php`).then((res) => {
                All.value = res.data.Content;
            });
        } catch (err) {
            console.log(err);
        }

        const getFeed = (publish) => {
            cookie.setCookie('cat_id', publish.cat_id);
            router.push({ name: "Contents", params: { id: publish.id } });
        };
        // try {
        //     HTTP.get(`GetAllCategories.php`).then((res) => {
        //         categories.value = res.data.Categories; 
        //     });
        // } catch (err) {
        //     console.log(err);
        // }
        
        return { categories, All, getFeed };
    },
}
</script>

<style>

</style>